import React from 'react';
import { RecoilRoot } from 'recoil';
import './App.css';
import MapManager from './containers/mapManager';

function App() {
  return <RecoilRoot><MapManager /></RecoilRoot>;
}

export default App;
